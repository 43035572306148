import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import img1 from "../../assets/img/telechargement/rehau/coloris/Rauho_1.jpg"
import img2 from "../../assets/img/telechargement/rehau/coloris/Rauho_2.jpg"


const RehauColorisPage = ({data}) => {
   return(
  <Layout>
    <SEO title="Catalogue" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 md:col-span-3 md:mB-5 mx-3"> {/** first element */}
      <h1 class="text-2xl font-bold mb-1 md:mt-5">Coloris</h1>
      <div class="border-b-4 border-red-500 w-1/12 mb-5 mt-0"></div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div class="relative">
                <a href="https://www.rehau.com/download/1851388/mat%C3%A9riaux-de-surface-rauvisio---surfaces-acryliques-exclusives-pour-l-am%C3%A9nagement-des-salles-de-bain.pdf" target="top">
                  <img src={img1} alt="REHAU Coloris" />
                  <p class="absolute top-1/3 font-bold text-white text-xl text-center mx-5">Matériaux de surface RAUVISIO - Surfaces acryliques exclusives pour l’aménagement des salles de bain</p>
                  <p class="absolute ">{/*Télécharger*/}</p>
                </a>
              </div>
              <div class="relative">
                <a href="https://www.rehau.com/download/1355072/rauvisio-mineral--cr%C3%A9ez-%C3%A0-l-infini.pdf" target="top">
                  <img src={img2} alt="REHAU Coloris" />
                  <p class="absolute top-1/3 font-bold text-white text-xl text-center mx-5">RAUVISIO mineral, créez à l'infini</p>
                  <p class="absolute top-2/3 text-center block ">{/*Télécharger*/}</p>
                  
                </a>
              </div>
            </div>
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>
)
}
 
export default RehauColorisPage